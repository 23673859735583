<script setup lang="ts">
const isNavOpen = ref(false)

const handleNavToggle = (isOpen: boolean) => {
  isNavOpen.value = isOpen
};

const enableDualSidenav = useAppConfig().enableDualSidenav

  // Initialize online status tracking
  const userUserOnlineStatus = useUserOnlineStatusStore()

  // onMounted only runs on the client side, so window is available.
  onMounted(() => {
  userUserOnlineStatus.initializeOnlineStatus()
    window.addEventListener('beforeunload', userUserOnlineStatus.cleanupOnlineStatus)
  })
  onBeforeUnmount(() => {
  userUserOnlineStatus.cleanupOnlineStatus()
    window.removeEventListener('beforeunload', userUserOnlineStatus.cleanupOnlineStatus)
  })
</script>

<template>
  <div class="bg-background">
    <!-- <SlLayoutTopBar /> -->
    <SlLayoutSideNavDual @toggle="handleNavToggle" />
    <div
      class="mt-16 transition-all duration-300 relative h-0"
      :class="{
        'ml-64': isNavOpen && enableDualSidenav,
        'ml-16': !isNavOpen && enableDualSidenav,
      }"
    >
      <div class="">
        <NuxtPage />
      </div>
    </div>

    <BotLoader />
  </div>
</template>
